<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <!-- 题库专题 -->
      <div class="bank-subject">
        <!-- 一级专题 -->
        <div class="bank-subject-parent">
          <div :class="'bank-subject-parent-item' + (currentSpecId == spec.id ? ' active' : '')"
            @click="changeSpec(spec)" v-for="spec in specList" :key="spec.id">
            <h1>{{ spec.title }}</h1>
          </div>
        </div>

        <!-- 二级专题、年份、科目 -->
        <div class="bank-subject-children">
          <div class="bank-subject-children-item">
            <div class="bank-subject-children-item-title">考试：</div>
            <div class="bank-subject-children-item-content">
              <div :class="currentSpecChildId == child.id ? 'active' : ''"
                @click="changeSpecChild(child)" v-for="child in specChildren" :key="child.id">
                {{ child.title }}
              </div>
            </div>
          </div>
          <div class="bank-subject-children-item">
            <div class="bank-subject-children-item-title">年份：</div>
            <div class="bank-subject-children-item-content">
              <div :class="currentYear == year ? 'active' : ''"
                @click="changeYear(year)" v-for="(year, index) in yearList" :key="index">
                {{ year }}
              </div>
            </div>
          </div>
          <div class="bank-subject-children-item">
            <div class="bank-subject-children-item-title">类型：</div>
            <div class="bank-subject-children-item-content">
              <div :class="currentSubjectId == subject.id ? 'active' : ''"
                @click="changeSubject(subject)" v-for="subject in subjectList" :key="subject.id">
                {{ subject.title }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 试卷类别 -->
      <div class="bank-type">
        <div :class="'bank-type-item' + (currentType == 7 ? ' active' : '')" @click="changeType(7)">模拟试题</div>
        <div :class="'bank-type-item' + (currentType == 2 ? ' active' : '')" @click="changeType(2)" >密押试卷</div>
        <div :class="'bank-type-item' + (currentType == 3 ? ' active' : '')" @click="changeType(3)">每日一练</div>
        <div :class="'bank-type-item' + (currentType == 4 ? ' active' : '')" @click="changeType(4)">章节练习</div>
        <div :class="'bank-type-item' + (currentType == 5 ? ' active' : '')" @click="changeType(5)">往年真题</div>
      </div>

      <!-- 试卷列表 -->
      <div class="bank-list" v-if="currentType != 2">
        <!-- 章节练习 -->
        <div v-if="currentType == 4">
          <el-collapse v-model="activeChapterList">
            <el-collapse-item v-for="(chapter, index) in paperList" :key="chapter.id" :name="index">
              <template #title>
                <div class="bank-list-chapter-title">{{ chapter.title }}</div>
              </template>
              <div class="bank-list-chapter-list">
                <el-row class="bank-list-row" v-for="paper in chapter.exam" :key="paper.id">
                  <el-col v-if="paper.question_num" :span="16">{{ paper.title }}</el-col>
                  <el-col v-if="paper.question_num" :span="4">
                    <i class="el-icon-star-on"></i>
                    <i class="el-icon-star-on"></i>
                    <i class="el-icon-star-on"></i>
                    <i class="el-icon-star-on"></i>
                    <i class="el-icon-star-on"></i>
                  </el-col>
                  <el-col v-if="paper.question_num" :span="4">
                    <div class="bank-list-row-btn" @click="goQuestion(paper)">开始做题</div>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- 非章节练习 -->
        <div v-else>
          <el-row class="bank-list-title">
            <el-col :span="16">试卷信息</el-col>
            <el-col :span="4">试卷热度</el-col>
            <el-col :span="4">做题</el-col>
          </el-row>
          <el-row class="bank-list-row" v-for="paper in paperList" :key="paper.id">
            <el-col v-if="paper.question_num" :span="16">{{ paper.title }}</el-col>
            <el-col v-if="paper.question_num" :span="4">
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
            </el-col>
            <el-col v-if="paper.question_num" :span="4">
              <div class="bank-list-row-btn" @click="goQuestion(paper)">开始做题</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 密押试卷 -->
      <div class="bank-list-secret" v-else>
        <div class="bank-list-secret-item" v-for="paper in paperList" :key="paper.id">
          <div class="secret-item-title">{{ paper.title }}</div>
          <div class="secret-item-num">试卷数量：{{ paper.exam_num }}套</div>
          <div class="secret-item-bottom">
            <div class="item-bottom-price">￥{{ paper.sale_price }}</div>
            <div class="item-bottom-btn" @click="goSecretDetail(paper)">开通题库</div>
          </div>
        </div>
      </div>
      <!-- 无数据 -->
      <el-empty v-if="!paperList.length" description="暂无数据"></el-empty>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getSpec, getSubject, getPaper, getTestGood } from '@/api/exam'
import { setSessionStorage, getSessionStorage ,getLocalStorage } from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';
	
export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 专题
      specList: [],
      // 当前选中的专题
      currentSpecId: null,
      // 子专题
      specChildren: [],
      // 选中的子专题
      currentSpecChildId: null,
      // 年份
      yearList: ["全部", "2021", "2020", "2019", "2018", "2017"],
      // 选中的年份
      currentYear: "全部",
      // 子专题下的科目
      subjectList: [],
      // 选中的科目
      currentSubjectId: null,
      // 选中的类型
      currentType: 7,
      // 试卷列表
      paperList: [],
      // 点开的章节练习
      activeChapterList: [],

    }
  },
  computed:{
  	...mapState(['token'])
  },
  created() {
    // 获取默认参数
    let examListParams = getSessionStorage('examListParams')
    // 获取专题
    getSpec().then((res) => {
      if (res.code == 1) {
        this.specList = res.data
        if (examListParams) {
          // 使用index页面传来的参数请求数据
          // 切换选中的类型
          this.currentType = examListParams.type || 7
          // 切换选中的专题
          this.currentSpecId = examListParams.specId
          // 获取当前专题下的二级专题列表
          this.specList.forEach(item => {
            if (item.id == this.currentSpecId) {
              this.specChildren = item.children
              return
            }
          })
          // 切换选中的二级专题
          this.currentSpecChildId = examListParams.specChildId
          // 获取当前二级专题下的科目
          this.getSubjectList(this.currentSpecChildId)
        } else {
          // 没有传入专题和二级专题，默认都取第一个
          this.changeSpec(res.data[0])
        }
      }
    })
    
  },
  methods: {
    // 切换专题
    changeSpec(spec) {
      this.currentSpecId = spec.id
      this.specChildren = spec.children
      // 同步子专题、科目
      this.changeSpecChild(spec.children[0])
    },
    // 切换二级专题
    changeSpecChild(child) {
      this.currentSpecChildId = child.id
      this.getSubjectList(child.id)
    },
    // 获取二级专题下的科目
    getSubjectList(specId) {
      getSubject({ spec_id: specId }).then((res) => {
        if (res.code == 1) {
    //       this.subjectList = res.data
		  // console.log(res.data)
		  this.subjectList = res.data.filter(item=>{
				return item.type==1 || item.type==2
		  })
          // 默认选中第一个科目
          this.currentSubjectId = res.data[0].id
          this.getPaperList()
        }
      })
    },
    // 获取试卷
    getPaperList() {
      if (this.currentType == 2) {
        // 将paperList置为空，防止页面数据跳闪
        this.paperList = []
        getTestGood({ spec_id: this.currentSpecChildId }).then((res) => {
          if (res.code == 1) {
            this.paperList = res.data
          }
        })
      } else {
		let data = this.currentYear == "全部"?{examtype_id: this.currentType, course_id: this.currentSubjectId}:{examtype_id: this.currentType, course_id: this.currentSubjectId, year: this.currentYear}

        getPaper(data).then((res) => {
          if (res.code == 1) {
			  this.paperList = res.data
          }
        })
      }
    },
    // 切换科目
    changeSubject(subject) {
      this.currentSubjectId = subject.id
      this.getPaperList()
    },
    // 切换年份
    changeYear(year) {
      this.currentYear = year
      this.getPaperList()
    },
    // 切换类型
    changeType(type) {
      this.currentType = type
      this.getPaperList()
    },
    // 做题
    goQuestion(paper) {
		if(getLocalStorage('token')){
			setSessionStorage('currentPaper', paper)
			this.$router.push('/question')
		}else{
			this.$notify.error({
			  title: '未登录',
			  message: '请先登录'
			});
			return;
		}
    },
    // 开通题库
    goSecretDetail(paper) {
      setSessionStorage("currentTestGood", paper)
      this.$router.push('/secretdetail')
    },

  },
};
</script>

<style scoped lang="scss">
/* 专题 begin */
.bank-subject {
  background-color: $background-color;
  box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
}
.bank-subject-parent {
  width: 100%;
  height: 60px;
  margin-top: 30px;
  border-bottom: 1px solid #eee;
  background-color: $background-color;
  display: flex;
}
.bank-subject-parent .bank-subject-parent-item {
  width: 150px;
  position: relative;
  cursor: pointer;
}
.bank-subject-parent .bank-subject-parent-item h1 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 18px;
}
.bank-subject-parent .bank-subject-parent-item:last-child h1 {
  border-right: none;
}
.bank-subject-parent .bank-subject-parent-item:hover h1,
.bank-subject-parent .bank-subject-parent-item.active h1 {
  color: $theme-color;
}
.bank-subject-parent .bank-subject-parent-item:hover::before,
.bank-subject-parent .bank-subject-parent-item.active::before {
  content: "";
  width: 48px;
  height: 2px;
  background-color: $theme-color;
  margin-left: -24px;
  position: absolute;
  left: 50%;
  bottom: -1px;
}
/* 专题 end */

/* 子专题 begin */
.bank-subject-children {
  padding: 5px 10px;
  margin-top: 10px;
}
.bank-subject-children > .bank-subject-children-item {
  margin-top: 3px;
  display: flex;
  align-items: baseline;
}
.bank-subject-children-item > .bank-subject-children-item-title {
  width: 66px;
  font-size: 18px;
}
.bank-subject-children-item > .bank-subject-children-item-content {
  color: #666;
  width: 1100px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}
.bank-subject-children-item-content > div {
  margin: 10px 15px;
  cursor: pointer;
}
.bank-subject-children-item-content > div:hover {
  color: $theme-color;
}
.bank-subject-children-item-content > div.active {
  color: $theme-color;
}
/* 子专题 end */

/* 类别 begin */
.bank-type {
  padding: 20px 10px;
  display: flex;
}
.bank-type > .bank-type-item {
  color: #666;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  background-color: $background-color;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.bank-type > .bank-type-item.active {
  color: $theme-color;
  background-color: #f2e3e3;
}
/* 类别 end */

/* 列表 begin */
.bank-list {
  text-align: center;
  margin: 30px 0;
  background-color: #f1f1f1;
}
.bank-list .bank-list-title {
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  font-weight: 700;
  background-color: $background-color;
}
.bank-list .bank-list-title > .el-col:first-child {
  text-align: left;
  text-indent: 170px;
}
.bank-list .bank-list-row {
  color: #666;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  border: 1px solid #eee;
  background-color: #fff;
  margin: 10px 0;
}
.bank-list .bank-list-row > .el-col:first-child {
  text-align: left;
  text-indent: 20px;
}
.bank-list .bank-list-row > .el-col:nth-child(2) {
  color: $danger-color;
}
.bank-list .bank-list-row .bank-list-row-btn {
  color: $danger-color;
  width: 160px;
  height: 40px;
  line-height: 40px;
  border: 1px solid $danger-color;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
}
.bank-list .bank-list-row .bank-list-row-btn:hover {
  color: #fff;
  background-color: $theme-color;
  border: none;
}
.bank-list-secret {
  display: flex;
  flex-wrap: wrap;
}
.bank-list-secret > .bank-list-secret-item {
  width: 300px;
  height: 150px;
  box-shadow: 0 1px 8px hsl(0deg 0% 65% / 16%);
  border-radius: 5px;
  background-color: $background-color;
  padding: 20px;
  margin-left: 30px;
  margin-bottom: 40px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.bank-list-secret-item .secret-item-title {
  height: 48px;
  line-height: 24px;
  font-size: 18px;
  overflow: hidden;
}
.bank-list-secret-item .secret-item-num {
  color: #999;
  font-size: 16px;
  margin-top: 10px;
}
.bank-list-secret-item .secret-item-bottom {
  color: $danger-color;
  font-size: 18px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-list-secret-item .secret-item-bottom > .item-bottom-price {
  color: $price-color;
}
.bank-list-secret-item .secret-item-bottom > .item-bottom-btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid $danger-color;
  border-radius: 5px;
  cursor: pointer;
}
.bank-list-secret > .bank-list-secret-item:hover .secret-item-bottom > .item-bottom-btn {
  color: #fff;
  background-color: $theme-color;
  border: none;
}
.bank-list-chapter-title {
  font-size: 16px;
  padding-left: 20px;
}
.bank-list-chapter-list .bank-list-row {
  background-color: #f1f1f1;
  margin: 0 auto;
}
/* 列表 end */
</style>
